<template>
	<div id="staging">
		<!-- 我的工作台 -->
		<div class="UploadDesignDraft" v-loading="loading" element-loading-text="上传中···"
			element-loading-spinner="el-icon-loading">
			<div class="title"><i>*</i>上传作品封面/文档</div>

			<el-upload class="upload-demo" drag :action="`${baseUrl}/spider/web/fileNetdisk/uploadImage`"
				:headers="{Authorization:`Bearer ${Authorization}`}" multiple :data="{type:'spider'}"
				:on-exceed="handExceed" :on-success="handSuccess" :before-upload="beforeUpload" list-type="picture"
				:limit="10" :file-list="fileList">
				<i class="el-icon-upload"></i>
				<div class="el-upload__text">
					将文件拖到此处，或
					<em>点击上传</em>
				</div>
				<div class="el-upload__tip" slot="tip">
					限制文件大小为300M，最多上传10个文件
				</div>
				<div class="listPreview" slot="file" slot-scope="{file}">
					<div class="options success" v-if="file.status == 'success'"><i class="el-icon-success"></i></div>
					<div class="options delete" @click="handleRemove(file)"><i class="el-icon-delete-solid"></i></div>
					<el-image class="imgPreview" :src="file.url" @click="handlePreview(file)" fit="contain"
						v-if="file.raw == '2'">
						<div slot="error" class="image-slot">
							<i class="el-icon-picture-outline"></i>
						</div>
					</el-image>
					<img class="imgPreview" :src="require('../../assets/images/video.svg')" v-else-if="file.raw == '3'">
					<img class="imgPreview" :src="require('../../assets/images/default.svg')" v-else>
					<div class="mainPreview">
						<el-row>
							<el-col :span="2"> 标题： </el-col>
							<el-col :span="22">
								<el-input v-model="file.name" size="mini" placeholder="请输入标题" readonly clearable
									@focus="changeFileName(file)"></el-input>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="2"> 类型： </el-col>
							<el-col :span="22">
								<el-select style="width: 100%" v-model="file.raw" size="mini" placeholder="请选择类型"
									clearable filterable>
									<el-option v-for="item in tagTreeList" :key="item.id" :label="item.tagName"
										:value="item.id">{{ item.tagName }}
									</el-option>
								</el-select>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="2"> 标签： </el-col>
							<el-col :span="22">
								<el-cascader placeholder="请选择标签" v-model="file.tags" :options="tagList"
									:show-all-levels="false" popper-class="popperSelect"
									:props="{ multiple: true, value:'id', label:'tagName', expandTrigger:'hover' }"
									clearable style="width: 100%;" size="mini"></el-cascader>
							</el-col>
							<!-- 	<template v-for="(tag,i) in tagList">
								<el-col :span="6" :key="i">
									<span>{{item.tagName}}：</span>
									<el-select style="width: 100%" v-model="file.item" size="mini" placeholder="请选择标签"
										clearable filterable>
										<el-option v-for="item in tagList" :key="item.id" :label="item.tagName"
											:value="item.id">{{ item.tagName }}
										</el-option>
									</el-select>
								</el-col>
							</template> -->
						</el-row>
					</div>
					<el-progress v-if="file.percentage" :percentage="file.percentage"
						:color="customColors"></el-progress>
				</div>
			</el-upload>
			<!-- <div class="title">选择设计类目</div>
			<div class="select">
				<span><i>*</i>所属机构</span>
				<el-select v-model="form.mechanismId" placeholder="请选择">
					<el-option v-for="item in mechanismList" :key="item.deptId" :label="item.deptName"
						:value="item.deptId">
					</el-option>
				</el-select>
			</div>
			<div class="select">
				<span>所属项目</span>
				<el-cascader v-model="form.itemId" :options="itemList" placeholder="请选择项目" clearable
					:props="{ expandTrigger: 'hover',label:'itemName',value:'id'}"></el-cascader>
			</div> -->
			<div class="options">
				<el-button type="warning" plain @click="resetForm">重置表单</el-button>
				<el-button type="primary" @click="saveOrUpdateFun">上传文档</el-button>
			</div>
		</div>

		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog>

		<el-dialog title="修改文档标题" :visible.sync="dialogVisible2"
			style="left:50%;top:50%;transform: translate(-50%,-50%) !important;">
			<el-input v-model="rowFileData.name" placeholder="请输入标题" clearable></el-input>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible2 = false;">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		tagTreeList, // 服务类型列表
		mechanismList, // 机构列表查询 
		itemList, // 获取项目列表
		saveOrUpdate //新增或修改网盘文件
	} from '@/request/apiStaging.js';
	import {
		fileNetdisk //获取网盘内容详情
	} from '@/request/apiNetDisk.js';
	import baseUrl from "@/request/config.js"
	export default {
		name: 'staging',
		components: {},
		data() {
			return {
				loading: false,
				baseUrl: '',
				dialogVisible: false,
				dialogImageUrl: '',
				tagTreeList: [], // 服务类型列表
				tagList: [], // 标签列表
				mechanismList: [], // 机构列表
				itemList: [], // 项目列表
				fileList: [],
				form: {
					id: "",
					title: "", // 标题
					filePath: "", // 网盘链接
					filePsd: "", // 网盘密码
					imagePath: "", // 文件/图片链接
					itemId: "", // 项目id
					mechanismId: "", // 机构id
					tagIds: "", // 服务类型id
				},
				Authorization: '',
				customColors: [{
						color: '#e6a23c',
						percentage: 50
					},
					{
						color: '#5cb87a',
						percentage: 100
					}
				],
				rowFileData: '',
				dialogVisible2: false,
			}
		},
		created() {
			this.resetForm()
			this.Authorization = localStorage.getItem('headToken');
			this.baseUrl = baseUrl;
			this.gettagTreeList();
			this.getTagList();
			this.geTmechanismList();
			this.getitemList();

		},
		mounted() {

		},
		methods: {
			handleChange(value) {
				value.tags = value.tags.filter((path) => path.length).map((path) => path[path.length - 1]);
				console.log(JSON)
			},

			// 修改文档标题
			changeFileName(file) {
				console.log(file)
				this.rowFileData = file;
				this.dialogVisible2 = true;
			},

			changeFileNameSubmit() {},

			// 重置表单
			resetForm() {
				this.form = {
					id: "",
					// title: "", // 标题
					// filePath: "", // 网盘链接
					// filePsd: "", // 网盘密码
					// imagePath: "", // 文件/图片链接
					files: '',
					// itemId: [], // 项目id
					// mechanismId: "", // 机构id
					// tagIds: "", // 服务类型id
				}
				this.fileList = []
			},

			getFileNetdisk(id) {
				fileNetdisk(id).then(res => {
					if (res.code == 200) {
						this.form.id = res.data.id;
						// itemId: res.data.itemId == ',' ? [] : res.data.itemId.split(','), // 项目id
						// mechanismId: res.data.deptId.toString(), // 机构id
						this.fileList = [{
							url: res.data.imagePath,
							name: res.data.title,
							raw: res.data.tagId,
							tags: res.data.fileTags
						}];

						// JSON.parse(JSON.stringify(this.tagList)).map((item, i) => {
						// 	return item.children
						// }).flat().map(item => {
						// 	return [item.parentId, item.id]
						// }).map(item => {
						// 	res.data.fileTags.map(tag => {
						// 		if (item[1] == tag) this.fileList[0].tags.push(item)
						// 	});
						// })
						// console.log(this.fileList)
					}
				})
			},




			// 新增或修改网盘文件
			saveOrUpdateFun() {
				let data = this.form;
				// console.log(data)
				let files = this.fileList.map(item => {
					item.title = item.name;
					item.tagId = item.raw;
					item.imagePath = item.response;
					if (item.tags) {
						item.tags = item.tags.map(item => {
							return item[item.length - 1];
						})
					}
					return item
				})
				data.files = files;

				// console.log(JSON.parse(JSON.stringify(data)));
				// return false;

				// if (!data.mechanismId) {
				// 	this.$notify.error({
				// 		title: '错误',
				// 		message: '请选择机构',
				// 		position: 'bottom-right'
				// 	});
				// } else

				if (!data.files[0]) {
					this.$notify.error({
						title: '错误',
						message: '请上传文件',
						position: 'bottom-right'
					});
				} else {
					this.loading = true;
					saveOrUpdate(data).then(res => {
						this.loading = false;
						if (res.code == 200) {
							this.$notify({
								title: '发布成功',
								message: '发布成功',
								type: 'success',
								position: 'bottom-right'
							});
							this.$router.push({
								name: 'NetDisk'
							})
						}
					}).catch(err => {
						this.loading = false;
					})

				}
			},

			// 获取服务类型列表
			gettagTreeList() {
				tagTreeList().then(res => {
					if (res.code == 200) {
						this.tagTreeList = res.rows;
					}
				})
			},
			// 获取标签列表
			getTagList() {
				let data = {
					parentId: 999
				}
				tagTreeList(data).then(res => {
					if (res.code == 200) {
						this.tagList = res.rows;

						if (this.$route.query.id) {
							this.getFileNetdisk(this.$route.query.id);
						}
					}
				})
			},

			// 机构列表查询
			geTmechanismList() {
				mechanismList().then(res => {
					if (res.code == 200) {
						this.mechanismList = res.rows;
					}
				})
			},

			// 获取项目列表
			getitemList() {
				itemList().then(res => {
					if (res.code == 200) {
						this.itemList = res.rows
					}
				})
			},

			handExceed() {
				this.$notify({
					title: '提示',
					message: `当前限制选择10个文件，上传文件数量超限。`,
					type: 'warning',
					position: 'bottom-right'
				});
			},

			beforeUpload(file) {
				const isLt10M = file.size / 1024 / 1024 > 300;
				if (isLt10M) {
					this.$alert('上传文件大小超限，请控制文件大小。', '提示', {
						confirmButtonText: '确定',
						type: 'warning',
					});
					return !isLt10M;
				}
			},

			handSuccess(res, file, fileList) {
				if (res.code == 500) {
					let index = fileList.findIndex(item => {
						if (item.name == file.name) {
							return item.name == file.name;
						}
					})
					fileList.splice(index, 1)
					this.$notify({
						title: '提示',
						message: res.msg,
						type: 'warning',
						position: 'bottom-right'
					});
				} else {
					this.fileList = fileList.map(item => {
						// let name = item.name.split('.').slice(0, -1).join('');
						// console.log(name)
						// item.name = name
						if (item.raw.type) {
							let type = item.raw.type.split('/')[0];
							if (type == 'application') {
								item.raw = '1';
							} else if (type == 'image') {
								item.raw = '2';
							} else if (type == 'video') {
								item.raw = '3';
							} else {
								item.raw = '4';
							}
						}
						return item;
					});

					console.log(JSON.parse(JSON.stringify(fileList)))
				}

			},

			handleRemove(file) {
				let index = this.fileList.findIndex(item => {
					return item.name == file.name;
				})
				console.log(index)
				this.fileList.splice(index, 1)
			},

			handlePreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
		}
	}
</script>

<style lang="less" scoped>
	#staging {
		padding: 50px;
		background-color: #F4F4F4;
		min-height: 100vh;

		.UploadDesignDraft {
			background: #FFFFFF;
			border-radius: 20px;
			padding: 50px;
			margin-bottom: 50px;

			.title {
				font-size: 22px;
				color: #181B1A;
				margin-bottom: 40px;

				i {
					font-style: normal;
					color: red;
					margin-right: 2px;
				}
			}

			.select {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-bottom: 40px;

				&:last-child {
					margin-bottom: 0;
				}

				span {
					width: 100px;
					margin-right: 20px;
					text-align: right;
					font-size: 16px;

					i {
						font-style: normal;
						color: red;
						margin-right: 2px;
					}
				}

				.el-autocomplete,
				.el-input,
				.el-textarea,
				.el-select,
				.el-cascader {
					flex: 1;
					max-width: 500px;
				}
			}

			.upload-demo {
				margin-bottom: 40px;
				max-width: 700px;
				padding: 0 35px;

				/deep/.el-upload {
					width: 100%;

					.el-icon-upload {
						font-size: 100px;
						margin-bottom: 20px;
					}

					.el-upload__text {
						font-size: 18px;
					}

					.el-upload-dragger {
						width: 100%;
						background: rgba(24, 27, 26, 0.04);
						border-radius: 16px;
						border: 1px dashed rgba(24, 27, 26, 0.3);
					}
				}

				.el-upload__tip {
					font-size: 14px;
				}

				/deep/.el-upload-list {
					.el-upload-list__item {
						display: flex;
						padding: 10px;
						height: auto;
						position: relative;

						.el-progress {
							position: absolute;
							height: 5px;
							left: 0;
							bottom: 0;
							right: 0;
						}

						.options {
							position: absolute;
							right: 0;
							bottom: 0;
							width: 0;
							height: 0;
							border: 20px solid rgba(0, 0, 0, 0);
							cursor: pointer;

							i {
								position: absolute;
								left: 60%;
								top: 60%;
								color: #F4F4F4;
								cursor: pointer;
							}
						}

						.success {
							border-right-color: #67C23A;
							border-bottom-color: #67C23A;
						}

						.delete {
							border-right-color: #F56C6C;
							border-bottom-color: #F56C6C;
							display: none;
						}

						.listPreview {
							flex: 1;
							display: flex;

							&:hover {
								.success {
									display: none;
								}

								.delete {
									display: block;
								}
							}


							.imgPreview {
								width: 70px;
								// height: 70px;
								margin-right: 10px;
								cursor: pointer;

								.image-slot {
									display: flex;
									justify-content: center;
									align-items: center;
									width: 100%;
									height: 100%;
									font-size: 40px
								}
							}

							.mainPreview {
								flex: 1;
								display: flex;
								flex-direction: column;
								justify-content: space-around;
								padding-right: 20px;

								.el-row {
									display: flex;
									align-items: center;
									margin-bottom: 5px;

									&:last-child {
										margin: 0;
									}
								}


							}
						}
					}
				}
			}

			.options {
				display: flex;
				justify-content: center;
				max-width: 700px;

				.el-button {
					margin: 0 20px;
				}
			}
		}


	}
</style>
<style lang="less">
	.popperSelect {
		li[aria-haspopup="true"] {
			.el-checkbox {
				display: none;
			}
		}
	}
</style>